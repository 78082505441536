import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUserSalesNetwork(ctx, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/sales-network/user-sales-network/${userId}`, { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    /*
    fetchSalePersonParent(ctx, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/sales-network/parent/${userId}`, { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    */
    /*
    fetchSalesNetworkChildren(ctx, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/sales-network/children/${userId}`, { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    */
    fetchSalesNetworkTree(ctx, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/sales-network/tree/${userId}`, { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllSalesNetwork(ctx, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/sales-network/all-sales-network`, { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllSalesNetworkUsers(ctx, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/sales-network/all-sales-users`, { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchChildrenSalesNetworkUsers(ctx, userId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/sales-network/children-sales-users`, { })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSalesNetworkItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/sales-network/add', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSalesNetworkItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/sales-network/update', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSalesAgencyParent(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/sales-network/update-sales-agencies-parent', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeSalesAgencyParent(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/sales-network/remove-sales-agencies-parent', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAgencySalesNetwork(ctx, params) { // Non utilizzata al momento
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/sales-network/agencies', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSalesNetworkItem(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/sales-network/delete', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllDealer(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/agency/agency-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgencies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/agency/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsersByAgencies(ctx, agencyId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/agency/${agencyId}/users`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgency(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/agency/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAgency(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/agency/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AddUpdateAgency(ctx, { agency, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/agency/addupdate', { obj: agency, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/countries')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
